import { combineReducers } from 'redux';

// reducer import
import homeReducer from './reducers/home';
import matchReducer from './reducers/match';
import configReducer from './reducers/config';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    home: homeReducer,
    match: matchReducer,
    config: configReducer
});

export default reducer;