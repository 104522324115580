import * as actionTypes from '../types';
import {fetchGames} from '../actions';
import {store} from '../index';
import {error, pre, success} from '../../_data/strings';

export const initialState = {
    data: [],
    state: pre
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const homeReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.FETCH_GAMES.type:
            store.dispatch(fetchGames())
            return state
        case actionTypes.FETCH_GAMES_SUCCESS:
            return {
                ...state,
                data: action.payload,
                state: success
            }
        case actionTypes.FETCH_GAMES_ERROR:
            return {
                ...state,
                state: error
            }
        default:
            return state
    }
};

export default homeReducer;