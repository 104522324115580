// material-ui
import {
    Box, Button,
    Container,
    CssBaseline,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid
} from '@mui/material';
import {Outlet} from 'react-router-dom';
import Loadable from '../../ui-component/Loadable';
import {lazy} from 'react';
import {useDetectAdBlock} from "../../hooks/useDetectAdBlock";

const Sidebar = Loadable(lazy(() => import('./Sidebar')));
const Header = Loadable(lazy(() => import('./Header')));
const Footer = Loadable(lazy(() => import('./Footer')));
const Alternative = Loadable(lazy(() => import('./Alternative')));
// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const adBlockDetected = useDetectAdBlock();
    return (
        <Box>
            <CssBaseline />
            <Header />
            <Alternative/>
            <Container maxWidth='xl'>
                <Grid container spacing={2}>
                    <Sidebar key='left' />
                    <Grid item xs md={6}>
                        {/*adBlockDetected ? <Dialog open={adBlockDetected}>
                            <DialogTitle>{"Adblock Detected"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Uh oh. Looks like you are using an ad blocker .
                                    We charge advertisers instead of our audience .
                                    Please whitelist our site to get access to our content
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => window.location.reload()} autoFocus>
                                    Agree
                                </Button>
                            </DialogActions>
                        </Dialog> : ''*/}
                        <Outlet />
                    </Grid>
                    <Sidebar key='right' />
                </Grid>
            </Container>
            <Footer />
        </Box>
    );
};

export default MainLayout;