import Routes from './routes';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import ReactGA from 'react-ga';

function App() {
    const location = useLocation();
    useEffect(() => {
        ReactGA.initialize("UA-84546669-5")
    }, []);
    useEffect(() => {
        ReactGA.pageview(location.pathname)
    }, [location]);

    return (
        <ThemeConfig>
            <GlobalStyles/>
            <Routes/>
        </ThemeConfig>
    );
}

export default App;
